<template>
  <v-autocomplete
    v-model="value"
    :items="options"
    :label="label"
    :placeholder="placeholder"
    dense
    outlined
    clearable
    hide-details
    single-line
  ></v-autocomplete>
</template>

<script>
import {httpClient} from "@/libs/http";
import {debounce} from "lodash/function";

export default {
  name: 'SelectEMarketShop',
  props: {
    name: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => 'Shop'
    },
    placeholder: {
      type: String,
      default: () => 'Shop'
    },
    filters: {
      type: Object,
      default: () => {}
    },
    isCheckEMarket: {
      type: Boolean,
      default: () => true
    },
  },
  data: () => ({
    value: null,
    options: []
  }),
  computed: {

  },
  watch: {
    value() {
      this.onFilter()
    },
    filters() {
      if (this.isCheckEMarket) {
        this.getList()
      }
    },
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit('onFilter', {
        name: this.name,
        value: this.value
      })
      const selected = [...this.options].find(o => o.value === this.value)
      this.$emit('itemSyncStock', (selected && selected.auto_sync_stock || 0))
      this.$emit('itemIdWarehouse', (selected && selected.id_warehouse || 0))
    }, 100),
    async getList() {
      if (!this.filters.id_e_market && this.isCheckEMarket) {
        this.options = []
        this.value = null
        return false
      }
      const {data} = await httpClient.post('/list-e-market-shop', this.filters)
      this.options = data.map(e => ({
        value: e.id,
        text: e.display_name || '',
        auto_sync_stock: e.auto_synchronize_stock,
        id_warehouse: e.id_warehouse
      }))
    },
  },
  mounted() {
    this.getList()
  }
}
</script>
